import { Modal as AModal } from 'antd';
import React, { FC, useMemo, useRef, useState } from 'react';

import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import { ContainerState } from 'containers/ProductDetailsPage/types';
import styled from 'styled-components';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import translations from 'translations';
import utilMessages from 'utils/messages';
import Image from '../Image/Image';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import SlideButtonIcon from './slideButton.svg';
import PlayVideoButton from './PlayVideoButton.svg';

const Modal = styled(AModal)`
  width: 100% !important;
  max-width: 600px;
  .ant-modal-footer {
    margin: 0px !important;
  }
  .ant-modal-content {
    padding: 20px !important;
  }
`;

const SwiperWrapper = styled.div`
  position: relative;
`;

const SlideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  ${({ nextButton }) =>
    nextButton &&
    `
    left: unset;
    right: 10px;
    transform: translateY(-50%) rotate(0deg) !important;
  `}
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 28px;
  height: 28px;
  border-radius: 24px;
  z-index: 1000;
  background: rgba(29, 30, 32, 0.3);
  cursor: pointer;
  & > div > div > img {
    margin-left: 3px;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  padding-bottom: 10px;
  overflow: auto;
`;

const ThumbnailItem = styled(SwiperSlide)`
  height: 100px !important;
  width: 100px !important;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    border: 1px solid #43A047
  `}
`;

const VideoThumbnailWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const VideoThumbnailButton = styled(SVGIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface IProps {
  product: ContainerState['product'];
  open: boolean;
  activeIndexProp: number;
  onClose: () => void;
}

const ProductImagesModal: FC<IProps> = (props) => {
  const { product, open, activeIndexProp, onClose } = props;
  const swiperRef = useRef(null as any);
  const videoRef = useRef(null as any);
  const [activeIndex, setActiveIndex] = useState(activeIndexProp);
  const showSlideButton = useMemo(() => {
    return {
      prev: activeIndex !== 0,
      next: product?.medias?.length && activeIndex < product?.medias?.length - 1,
    };
  }, [activeIndex, product?.medias]);

  const handleManipulateVideo = (currentIndex) => {
    if (videoRef.current) {
      if (currentIndex === 0) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleClickThumbnail = (index) => {
    setActiveIndex(index);
    if (swiperRef.current && swiperRef.current.slideTo) {
      swiperRef.current.slideTo(index, 500);
    }
    handleManipulateVideo(index);
  };

  return (
    <ErrorBoundary>
      <Modal open={open} footer={false} destroyOnClose={true} onCancel={onClose} centered>
        <div>
          <SwiperWrapper>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              initialSlide={activeIndexProp}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
                handleManipulateVideo(swiper.activeIndex);
              }}
            >
              {product?.medias?.map((media, index) => (
                <SwiperSlide key={index}>
                  {media?.type === 'VIDEO' ? (
                    <video
                      ref={videoRef}
                      autoPlay
                      controls
                      style={{ width: '100%', maxHeight: '560px', height: '100%' }}
                      src={media?.url || ''}
                    />
                  ) : (
                    <Image
                      zoomIn={false}
                      responsive
                      src={!product.loading ? media?.url || getImageByCategory(product.category) : ''}
                      alt={product.name ?? translations(utilMessages.productDetailImage)}
                      nopadding
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            {showSlideButton.prev && (
              <SlideButton onClick={() => handleClickThumbnail(activeIndex - 1)}>
                <SVGIcon src={SlideButtonIcon} width="20" height="20" />
              </SlideButton>
            )}
            {showSlideButton.next && (
              <SlideButton nextButton={true} onClick={() => handleClickThumbnail(activeIndex + 1)}>
                <SVGIcon src={SlideButtonIcon} width="20" height="20" />
              </SlideButton>
            )}
          </SwiperWrapper>
          <ThumbnailWrapper>
            {product?.medias?.map((media, index) => (
              <ThumbnailItem
                onClick={() => handleClickThumbnail(index)}
                key={index}
                style={{ width: 'fit-content' }}
                active={index === activeIndex}
              >
                {media?.type === 'VIDEO' ? (
                  <VideoThumbnailWrapper>
                    <video style={{ width: '100%', height: '100%' }} src={media?.url || ''} />
                    <VideoThumbnailButton src={PlayVideoButton} />
                  </VideoThumbnailWrapper>
                ) : (
                  <Image
                    zoomIn={false}
                    responsive
                    src={!product.loading ? media?.url || getImageByCategory(product.category) : ''}
                    alt={product.name ?? translations(utilMessages.productDetailImage)}
                    nopadding
                  />
                )}
              </ThumbnailItem>
            ))}
          </ThumbnailWrapper>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default ProductImagesModal;
